.circularContainer {
  width: 100px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
}

.phpicture {
  width: 100%;
  height: 100%;
  background-color: #4b5751; /* Placeholder background color */
  border-radius: 50%; /* Make it circular */
}

.loadinganimation {
  animation: loading 1s infinite alternate; /* Example animation */
}

.placeholderLine {
  width: 50%;
  height: 10px;
  background-color: #4b5751; /* Placeholder background color */
}

@keyframes loading {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}
