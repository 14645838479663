.right-column-order {
  height: 60vh;
  overflow-y: auto; /* Only allow vertical scroll */
  overflow-x: hidden; /* Prevent horizontal scroll */
}

@media (max-width: 576px) {
  /* xs */
  .right-column-order {
    height: 100vh;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  /* sm */
  .right-column-order {
    height: 100vh;
  }
}
.order-status-icons-container {
  /* Default styling for xs and sm */
}

.order-status-icon {
  /* Default styling for xs and sm */
}

.order-status-icon-circle {
  /* Default styling for xs and sm */
  width: 0.6em;
  height: 0.6em;
}

.order-status-icon-text {
  /* Default styling for xs and sm */
  font-size: 8px;
}

@media (min-width: 768px) {
  /* md */
  .order-status-icon-circle {
    width: 0.8em;
    height: 0.8em;
  }
  .order-status-icon-text {
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  /* lg */
  .order-status-icon-circle {
    width: 1em;
    height: 1em;
  }
  .order-status-icon-text {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  /* xl */
  .order-status-icon-circle {
    width: 1.2em;
    height: 1.2em;
  }
  .order-status-icon-text {
    font-size: 14px;
  }
}

@media (min-width: 1400px) {
  /* xxl */
  .order-status-icon-circle {
    width: 1.4em;
    height: 1.4em;
  }
  .order-status-icon-text {
    font-size: 16px;
  }
}
