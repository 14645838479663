.categoryCardImage {
  width: 60px !important;
  height: 60px;
}

@media (min-width: 768px) {
  .categoryCardImage {
    width: 70px !important;
    height: 70px;
  }
}

@media (min-width: 992px) {
  .categoryCardImage {
    width: 90px !important;
    height: 90px;
  }
}
