body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  box-sizing: border-box;
}
/* In your CSS file */
/* .nav-brand {
  color: #c0b394;
} */
.slick-slide:first-child {
  z-index: 10;
}

@media (min-width: 1200px) {
  /* Bootstrap xl breakpoint sizes buttons */
  .break-xl {
    flex-basis: 100%;
    height: 0;
  }
}

.my-offcanvas {
  width: 50% !important;
}
@media (max-width: 576px) {
  /* for screens smaller than or equal to 576px (sm) */
  .my-offcanvas {
    width: 60% !important;
  }
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

#lblCartCount {
  position: absolute;
  top: 0;
  right: 5;

  font-size: 16px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;

  text-align: center; /* Center the number */
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink:hover {
  animation: blinker 1s linear infinite;
}
/* contact us page */

.img-custom {
  width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .img-custom {
    height: 600px;
  }
}

.map-responsive {
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.subcategories-container {
  display: flex;
  flex-wrap: wrap; /* Wrap items to next line when they overflow */
  justify-content: center; /* Center items horizontally */
}

.subcategory-item {
  width: 150px; /* Set width of each subcategory item */
  margin: 10px; /* Add margin between items */
  text-align: center; /* Center text */
}

.subcategory-image {
  width: 90px;
  height: 90px;
  object-fit: cover; /* Ensure the image fully covers the container */
  border-radius: 50%; /* Apply border radius to images */
}
