@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//
// Carousel
// Based on https://swiperjs.com/react
// ------------------------------------

$fi-chevron-left: "\e951";
$fi-chevron-right: "\e952";
$fi-chevron-up: "\e953";
$fi-chevron-down: "\e950";
// Grayscale
$white: #fff !default;
$gray-100: #f9f8fa !default;
$gray-200: #f5f4f8 !default;
$gray-300: #efecf3 !default;
$gray-400: #d5d2dc !default;
$gray-500: #bbb7c5 !default;
$gray-600: #9691a4 !default;
$gray-700: #666276 !default;
$gray-800: #454056 !default;
$gray-900: #1f1b2d !default;
$black: #000 !default;

// Brand colors
$primary: #ffbf00 !default;
$accent: #5d3cf2 !default;
$secondary: $gray-200 !default;
$info: #3c76f2 !default;
$success: #07c98b !default;
$warning: #fdbc31 !default;
$danger: #f23c49 !default;
$light: $white !default;
$dark: $gray-900 !default;

$font-size-base: 1rem !default;
$font-size-lg: ($font-size-base * 1.125) !default;
$font-size-sm: ($font-size-base * 0.875) !default;
$font-size-xs: ($font-size-base * 0.75) !default;
$font-size-xxs: ($font-size-base * 0.625) !default;

// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: $gray-300 !default;
$border-light-color: rgba($white, 0.15) !default;

$border-radius-sm: 0.375rem !default;
$border-radius: 0.5rem !default;
$border-radius-lg: 0.75rem !default;
$border-radius-xl: 1.875rem !default;
$border-radius-pill: 50rem !default;
$box-shadow: 0 0.125rem 0.5rem -0.25rem rgba($gray-900, 0.12),
  0 0.25rem 1rem rgba($gray-900, 0.12) !default;

$box-shadow-lg: 0 0.25rem 0.75rem -0.375rem rgba($gray-900, 0.2),
  0 0.5rem 1rem rgba($gray-900, 0.16) !default;

$box-shadow-sm: 0 0.125rem 0.125rem -0.125rem rgba($gray-900, 0.08),
  0 0.25rem 0.75rem rgba($gray-900, 0.08) !default;

$box-shadow-hover: 0 0.25rem 0.75rem -0.375rem rgba($primary, 0.2),
  0 0.5rem 1rem rgba($primary, 0.16);
$box-shadow-inset: inset 0 1px 2px transparent !default;

$spacer: 1rem !default;

// Modifying Bootstrap global options

$enable-caret: false !default;
$enable-shadows: true !default;
$enable-negative-margins: true !default;
$enable-smooth-scroll: false !default;
// Carousel

$carousel-controls-size: 2.25rem !default;
$carousel-controls-font-size: $font-size-xs !default;
$carousel-controls-color: $gray-800 !default;
$carousel-controls-bg: $white !default;
$carousel-controls-hover-color: $primary !default;
$carousel-controls-hover-box-shadow: $box-shadow !default;
$carousel-controls-box-shadow: $box-shadow-sm !default;
$carousel-controls-transition: all 0.3s ease-in-out !default;

$carousel-controls-light-color: $white !default;
$carousel-controls-light-bg: rgba($white, 0.04) !default;
$carousel-controls-light-hover-color: $primary !default;
$carousel-controls-light-hover-bg: $white !default;

$carousel-nav-width: 1rem !default;
$carousel-nav-height: 0.375rem !default;
$carousel-nav-bg: rgba($gray-700, 0.4) !default;
$carousel-nav-active-bg: $gray-700 !default;
$carousel-nav-light-bg: rgba($white, 0.4) !default;
$carousel-nav-light-active-bg: $white !default;

$carousel-thumbnails-spacing: $spacer * 0.875 !default;
$carousel-thumbnail-width: 20% !default;
$carousel-thumbnail-border-radius: $border-radius !default;
$carousel-thumbnail-border-width: $border-width !default;
$carousel-thumbnail-border-color: transparent !default;
$carousel-thumbnail-active-border-color: $primary !default;
$carousel-thumbnail-opacity: 0.6 !default;
$carousel-thumbnail-hover-opacity: 1 !default;
$carousel-thumbnail-active-opacity: 1 !default;
$carousel-thumbnail-transition: opacity 0.2s ease-in-out,
  border-color 0.2s ease-in-out !default;

// Navigation (Prev / Next) buttons

.btn-prev,
.btn-next,
.swiper-button-prev,
.swiper-button-next {
  position: absolute !important;
  top: 50% !important;
  width: $carousel-controls-size !important;
  height: $carousel-controls-size !important;
  padding: 0;
  transition: $carousel-controls-transition !important;
  border-radius: 50% !important;
  background-color: $carousel-controls-bg !important;
  color: $carousel-controls-color !important;
  text-align: center !important;
  @if $enable-shadows {
    border: 0 !important;
    box-shadow: $carousel-controls-box-shadow !important;
  } @else {
    border: $border-width solid $border-color !important;
  }
  z-index: 10 !important;

  &:not([class*=" mt-"]) {
    margin-top: -($carousel-controls-size * 0.5) !important;
  }

  &:focus {
    outline: none;
  }

  &::after {
    font: {
      // family: "#{$icons-font-family}" !important;
      weight: normal;
      size: $carousel-controls-font-size !important;
    }
    line-height: $carousel-controls-size - 0.25rem !important;
  }

  &:hover {
    color: $carousel-controls-hover-color !important;
    @include box-shadow($carousel-controls-hover-box-shadow !important);
  }

  &.swiper-button-disabled,
  &[disabled] {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

.btn-prev {
  left: 0 !important;
}
.swiper-button-prev {
  left: $spacer !important;
}
.btn-prev::after,
.swiper-button-prev::after {
  margin-right: 0.0625rem;
  // content: $fi-chevron-left !important;
}

.btn-next {
  right: 0 !important;
}
.swiper-button-next {
  right: $spacer !important;
}
.btn-next::after,
.swiper-button-next::after {
  margin-left: 0.0625rem;
  // content: $fi-chevron-right !important;
}

.btn-prev,
.btn-next {
  &.position-relative {
    position: relative !important;
    margin-top: 0 !important;
  }
}

.swiper-nav-onhover {
  .btn-prev,
  .btn-next,
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0 !important;
  }
  .btn-prev,
  .swiper-button-prev {
    transform: translateX(1rem);
  }
  .btn-next,
  .swiper-button-next {
    transform: translateX(-1rem);
  }
  &:hover .btn-prev,
  &:hover .btn-next,
  &:hover .swiper-button-prev,
  &:hover .swiper-button-next {
    transform: none !important;
    opacity: 1 !important;
  }
}

.swiper-vertical {
  .btn-prev,
  .btn-next,
  .swiper-button-prev,
  .swiper-button-next {
    right: 0 !important;
    left: 50% !important;
    margin: {
      top: 0 !important;
      left: -($carousel-controls-size * 0.5) !important;
    }
  }
  .btn-prev {
    top: 0 !important;
  }
  .btn-next {
    bottom: 0 !important;
  }
  .swiper-button-prev {
    top: $spacer !important;
  }
  .swiper-button-next {
    top: auto !important;
    bottom: $spacer !important;
  }
  .btn-prev::after,
  .swiper-button-prev::after {
    margin: {
      right: 0;
      bottom: 0.125rem;
    }
    content: $fi-chevron-up !important;
  }
  .btn-next::after,
  .swiper-button-next::after {
    margin: {
      left: 0;
      top: 0.125rem;
    }
    content: $fi-chevron-down !important;
  }
  &.swiper-nav-onhover {
    .btn-prev,
    .swiper-button-prev {
      transform: translateY(1rem);
    }
    .btn-next,
    .swiper-button-next {
      transform: translateY(-1rem);
    }
  }
}

.btn-prev.btn-light,
.btn-next.btn-light {
  background-color: $carousel-controls-light-bg !important;
  color: $carousel-controls-light-color !important;
  box-shadow: none !important;
  &:hover {
    background-color: $carousel-controls-light-hover-bg !important;
    color: $carousel-controls-light-hover-color !important;
  }
}

// Pagination (Bullets)

.swiper-pagination:not(.swiper-thumbnails) {
  display: flex;
  justify-content: center;
  align-items: center;

  &.swiper-pagination-lock {
    display: none;
  }
}

.swiper-pagination-bullet:not(.swiper-thumbnail) {
  width: $carousel-nav-width !important;
  height: $carousel-nav-height !important;
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out,
    background-color 0.2s ease-in-out !important;
  border-radius: $border-radius-pill !important;
  background-color: $carousel-nav-bg !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active:not(.swiper-thumbnail) {
  background-color: $carousel-nav-active-bg !important;
}

.swiper-horizontal > .swiper-pagination-bullets:not(.swiper-thumbnails),
.swiper-pagination-bullets:not(
    .swiper-thumbnails
  ).swiper-pagination-horizontal {
  &:not(.bottom-0) {
    bottom: $spacer * 1.375 !important;
  }
  .swiper-pagination-bullet {
    margin: 0 0.3rem !important;
  }
  .swiper-pagination-bullet-active {
    width: $carousel-nav-width * 2 !important;
  }
}

.swiper-vertical > .swiper-pagination-bullets:not(.swiper-thumbnails),
.swiper-pagination-bullets.swiper-pagination-vertical:not(.swiper-thumbnails) {
  flex-direction: column !important;
  &:not(.end-0) {
    right: $spacer * 1.375 !important;
  }
  .swiper-pagination-bullet:not(.swiper-thumbnail) {
    width: $carousel-nav-height !important;
    height: $carousel-nav-width !important;
    margin: 0.3rem 0 !important;
  }
  .swiper-pagination-bullet-active {
    height: $carousel-nav-width * 2 !important;
  }
}

.swiper-pagination-light .swiper-pagination-bullet:not(.swiper-thumbnail) {
  background-color: $carousel-nav-light-bg !important;
  &.swiper-pagination-bullet-active {
    background-color: $carousel-nav-light-active-bg !important;
  }
}

// Lazy loading

.swiper-lazy {
  position: relative;
  z-index: 5;
}

.swiper-lazy-preloader {
  position: absolute;
  width: 2.25rem !important;
  height: 2.25rem !important;
  top: 50%;
  left: 50%;
  margin: {
    top: -1.125rem !important;
    left: -1.125rem !important;
  }
  border: 0.125rem solid currentColor !important;
  border-right-color: transparent !important;
  border-radius: 50%;
  color: $gray-700;
  animation: 0.75s linear infinite spinner-border;
  z-index: 2 !important;
}

// Slides count

.swiper-slides-count {
  position: absolute;
  display: flex;
  align-items: center;
  right: $spacer * 1.5;
  bottom: $spacer * 1.25;
  z-index: 10;
}

// Thumbnails

.swiper-thumbnails {
  display: flex !important;
  flex-wrap: wrap !important;
  width: auto !important;
  margin: {
    right: -($carousel-thumbnails-spacing * 0.5) !important;
    left: -($carousel-thumbnails-spacing * 0.5) !important;
  }
  padding: ($carousel-thumbnails-spacing * 0.5) 0 !important;
  list-style: none !important;
  &:focus {
    outline: none !important;
  }
}
.swiper-thumbnail {
  display: block !important;
  width: $carousel-thumbnail-width !important;
  height: auto !important;
  margin: 0 !important;
  padding: $carousel-thumbnails-spacing * 0.5 !important;
  border-radius: 0 !important;
  background: none !important;
  opacity: 1 !important;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  > img {
    display: block;
    width: 100%;
    transition: $carousel-thumbnail-transition;
    border: $carousel-thumbnail-border-width solid
      $carousel-thumbnail-border-color;
    border-radius: $carousel-thumbnail-border-radius;
    opacity: $carousel-thumbnail-opacity;
  }

  &:hover > img {
    opacity: $carousel-thumbnail-hover-opacity;
  }

  &.swiper-pagination-bullet-active {
    pointer-events: none !important;
    cursor: default !important;

    > img {
      border-color: $carousel-thumbnail-active-border-color;
      opacity: $carousel-thumbnail-active-opacity;
    }
  }
}
@include media-breakpoint-down(sm) {
  .swiper-thumbnails {
    margin: {
      right: -($carousel-thumbnails-spacing * 0.333) !important;
      left: -($carousel-thumbnails-spacing * 0.333) !important;
    }
    padding: ($carousel-thumbnails-spacing * 0.333) 0 !important;
  }

  .swiper-thumbnail {
    width: 33.33% !important;
    padding: $carousel-thumbnails-spacing * 0.333 !important;
  }
}

// Inside card

.card {
  > .card-img-hover {
    .swiper-button-prev,
    .swiper-button-next {
      transition: opacity 0.3s ease-in-out;
      background: none !important;
      color: $carousel-controls-light-color !important;
      box-shadow: none !important;
      opacity: 0 !important;
      &::after {
        font-size: $carousel-controls-font-size * 1.375 !important;
      }
    }
    .swiper-button-prev {
      left: 0.25rem !important;
    }
    .swiper-button-next {
      right: 0.25rem !important;
    }
  }

  &:hover > .card-img-hover .swiper-button-prev,
  &:hover > .card-img-hover .swiper-button-next {
    opacity: 1 !important;
  }
}

// Center slide

.swiper-centered {
  .swiper-slide {
    transition: opacity 0.15s ease-in-out;
    opacity: 0.6;
    .btn,
    a {
      pointer-events: none;
    }
    &.swiper-slide-active {
      opacity: 1;
      .btn,
      a {
        pointer-events: auto;
      }
    }
  }
}

// Layer animations

.swiper-slide {
  user-select: none;
  .from-top,
  .from-bottom,
  .from-start,
  .from-end,
  .scale-up,
  .scale-down {
    transition: all 0.45s 0.3s ease-in-out;
    opacity: 0;
    backface-visibility: hidden;
  }
  .from-top {
    transform: translateY(-45px);
  }
  .from-bottom {
    transform: translateY(45px);
  }
  .from-start {
    transform: translateX(-45px);
  }
  .from-end {
    transform: translateX(45px);
  }
  .scale-up {
    transform: scale(0.8);
  }
  .scale-down {
    transform: scale(1.2);
  }
  .delay-1 {
    transition-delay: 0.5s;
  }
  .delay-2 {
    transition-delay: 0.7s;
  }
  .delay-3 {
    transition-delay: 0.9s;
  }
  .delay-4 {
    transition-delay: 1.1s;
  }
  &.swiper-slide-active {
    .from-top,
    .from-bottom {
      transform: translateY(0);
      opacity: 1;
    }
    .from-start,
    .from-end {
      transform: translateX(0);
      opacity: 1;
    }
    .scale-up,
    .scale-down {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.lg-download {
  display: none !important;
}
