.phpicture {
  width: 100%;
  height: 100%;
  background-color: #4b5751; /* Placeholder background color */
}

.loadinganimation {
  animation: loading 1s infinite alternate; /* Example animation */
}

@keyframes loading {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}
