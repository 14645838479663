.product-col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 50px;
}
.text-link {
  text-decoration: none;
}
